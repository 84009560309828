// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-career-js": () => import("./../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fullstack-developer-js": () => import("./../src/pages/fullstack-developer.js" /* webpackChunkName: "component---src-pages-fullstack-developer-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-aycm-js": () => import("./../src/pages/projects/aycm.js" /* webpackChunkName: "component---src-pages-projects-aycm-js" */),
  "component---src-pages-projects-erste-js": () => import("./../src/pages/projects/erste.js" /* webpackChunkName: "component---src-pages-projects-erste-js" */),
  "component---src-pages-projects-fridai-js": () => import("./../src/pages/projects/fridai.js" /* webpackChunkName: "component---src-pages-projects-fridai-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-pulzus-js": () => import("./../src/pages/projects/pulzus.js" /* webpackChunkName: "component---src-pages-projects-pulzus-js" */),
  "component---src-pages-projects-workn-js": () => import("./../src/pages/projects/workn.js" /* webpackChunkName: "component---src-pages-projects-workn-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

